import React from 'react';
import clsx from 'clsx';

import arrow from '@images/polygon.svg';

const PlayIcon = ({ color, className }) => {
  return (
    <div className={clsx('d-flex justify-content-center align-content-center', className, {[`bg-${color}`]:color})} style={{borderRadius: 5, padding: 25}}>
      <img src={arrow} height={43} width={43} alt=''/>
    </div>
  )
}

export default PlayIcon
