import React from 'react';
import clsx from 'clsx';

import Img from '@common/Image';
import Link from '@common/Link';

import * as styles from '@styles/modules/footer.module.scss';

const FooterLogoColumn = ({ data }) => {
  const {
    footerLogo,
    socialMediaCopy,
    socialLinks
  } = data?.contentfulLayout;
  return (
    <div className='d-flex flex-column col-lg-2'>
      <div className={styles.logoImg} style={{marginTop: 11, marginBottom: 6}}>
        <Img image={footerLogo?.gatsbyImageData}  file={footerLogo?.file}/>
      </div>
      <div className='d-none d-lg-flex flex-column align-items-start'>
        <p className={clsx('fs-14', styles.linkMargin)}>{socialMediaCopy}</p>
        {socialLinks && socialLinks.map(((link, index) => (
          <Link key={index} to={link.link} aria-label={link.image?.title} className={clsx('d-flex align-items-center', styles.linkMargin)}>
            {link.image && <Img image={link.image?.gatsbyImageData}  file={link.image?.file}/>}
            {link.title && <p className='fs-14 fw-normal text-black ms-1 mb-0'>{link.title}</p>}
          </Link>
        )))}
      </div>
    </div>
  )
}

export default FooterLogoColumn
